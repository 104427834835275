// Packages
import styled from 'styled-components'

// components
import Button from '../Button'

export const StyledWeGo = styled.section`
  background-color: rgb(55, 204, 186, 0.4);
  margin: 1rem 0 2rem 0rem;
  mix-blend-mode: multiply;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.small}px) {
    display: flex;
    justify-content: center;
  }
`

export const StyledWrapper = styled.div`
  padding: 1.5rem 1rem;

  @media (min-width: ${props => props.theme.breakpoints.small}px) {
    max-width: 90%;
    padding: 1.5rem 0;
  }
`

export const StyledIconsContainer = styled.div`
  align-items: center;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 1rem 0;
`

export const StyledIconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  padding: 0 .5rem;
  position: relative;
`

export const StyledIcon = styled.div`  
  position: relative;
`

export const StyledCircle = styled.span`
  align-items: center;
  background-color: #30B2A0;
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  display: flex;
  font-size: 0.9rem;
  height: 1.5rem;
  justify-content: center;
  left: -0.25rem;
  position: absolute;
  top: -0.25rem;
  width: 1.5rem;
`

export const StyledIconWrapper = styled.img`
  color: #093046;
  width: 3rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    width: 3.25rem;
  }
`

export const StyledDescription = styled.p`
  font-weight: 700;
  line-height: 1.3;
  margin-top: 0.75rem;
  text-align: center;
`

export const StyledTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-align: left;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    text-align: center;
  }
`

export const StyledIconCarContainer = styled.span`
  margin-left: 1rem;
`

export const StyledButton = styled(Button)`
  width: 9rem;
`

export const StyledButtonContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`
