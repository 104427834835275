// Packages
import styled from 'styled-components'

// components
import WeKeepStandards from '../WeKeepStandards'
import CertificatesCarousel from '../CertificatesCarousel'

export const StyledHomepage = styled.div``

export const StyledWeKeepStandardsCertificatesCarouselMerged = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`

export const StyledWeKeepStandards = styled(WeKeepStandards)`
  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    width: 50%;
  }
`

export const StyledCertificatesCarousel = styled(CertificatesCarousel)`
  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    width: 50%;
  }
`

export const StyledWeKeepStandardsCertificatesCarouselWrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    align-items: center;
    display: flex;
    justify-content: center;
    max-width: 85%;
    padding-bottom: 1rem;
    padding-top: 2rem;
  }
`

export const StyledIframe = styled.iframe`
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const StyledWrapperIframe = styled.div`
	position: relative;
  height: 20rem;
  margin: 1rem 0;
  width: 100%;
`
