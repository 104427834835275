// Packages
import React, { memo } from 'react'

// Styled components
import { StyledHomeExperienceDisclaimer, StyledHomeExperienceDisclaimerContainer, StyledParagraph, StyledStrong } from './styledComponents'

const HomeExperienceDisclaimer = () => {
  return (
    <StyledHomeExperienceDisclaimer>
      <StyledHomeExperienceDisclaimerContainer>
        <StyledParagraph>          
            Somos <StyledStrong>un equipo de expertos</StyledStrong> cuidando tu salud, <StyledStrong>con 25 años de experiencia</StyledStrong>, que nos respaldan para seguir apoyando a <StyledStrong>tu economía y salud.</StyledStrong>
        </StyledParagraph>
      </StyledHomeExperienceDisclaimerContainer>
    </StyledHomeExperienceDisclaimer>
  )
}

export default memo(HomeExperienceDisclaimer)
