// Packages
import React from 'react'
import { navigate } from 'gatsby'

// Styled components
import { StyledWeKeepStandards, StyledContainer, StyledCopy, StyledSectionTitle, StyledButton, StyledImageContainer, StyledImage } from './styledComponents'

// Assets
import WeKeepStandarsImg from '../../../data/assets/content/we_keep_standards.jpg'

const WeKeepStandards = props => {  
  return (
    <StyledWeKeepStandards {...props}>
      <StyledContainer>
        <StyledCopy>
          <StyledSectionTitle>
            Mantenemos los estándares necesarios para el cuidado de tus resultados.
          </StyledSectionTitle>
          <StyledButton
            onClick={() => {
              navigate('/servicios')
            }}
          >
            Conoce nuestros servicios
          </StyledButton>
        </StyledCopy>
        <StyledImageContainer>
          <StyledImage src={WeKeepStandarsImg} alt='' />
        </StyledImageContainer>
      </StyledContainer>
    </StyledWeKeepStandards>
  )
}

export default WeKeepStandards
