// Packages
import React from 'react'

// Components
import Hero from '../Hero'
import HomeExperienceDisclaimer from '../HomeExperienceDisclaimer'
import WeGo from '../WeGo'
import VisitUs from '../VisitUs'
import OurClients from '../OurClients'

// Styled components
import { StyledHomepage, StyledWeKeepStandardsCertificatesCarouselMerged, StyledWeKeepStandards, StyledCertificatesCarousel, StyledWeKeepStandardsCertificatesCarouselWrapper, StyledWrapperIframe, StyledIframe } from './styledComponents'

const Homepage = ({ visitUsImages, banners }) => {
  return (
    <StyledHomepage>
      <Hero slides={banners} />
      <HomeExperienceDisclaimer />
      <StyledWeKeepStandardsCertificatesCarouselMerged>
        <StyledWeKeepStandardsCertificatesCarouselWrapper>
          <StyledWeKeepStandards />
          <StyledCertificatesCarousel />
        </StyledWeKeepStandardsCertificatesCarouselWrapper>
      </StyledWeKeepStandardsCertificatesCarouselMerged>
      <StyledWrapperIframe>
        <StyledIframe
          width='560'
          height='315'
          src='https://www.youtube.com/embed/JTCWq3PmLhY?autoplay=1'
          frameborder='0'
          allowfullscreen
        />
      </StyledWrapperIframe>
      <WeGo />
      <VisitUs slides={visitUsImages} />
      <OurClients />
    </StyledHomepage>
  )
}

export default Homepage
