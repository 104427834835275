// Packages
import React from 'react'
import { navigate } from 'gatsby'
import Slider from 'react-slick'
import '../../../../node_modules/slick-carousel/slick/slick-theme.css'
import '../../../../node_modules/slick-carousel/slick/slick.css'

// Assets
import useData from '../../../hooks/useData'

// Styled components
import { StyledVisitUs, StyledVisitUsContainer, StyledCopyContainer, StyledSliderContainer, StyledButton, StyledParagraph, StyledStrong, StyledFluidImage, StyledSlideContainer, StyledTitle } from './styledComponents'

const VisitUs = ({ slides }) => {
  const { constants: { sliderCommonSettings } } = useData()

  const sliderSettings = {
    ...sliderCommonSettings,    
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  }

  const slidesRendered = slides.map((slide, index) => (
    <StyledSlideContainer key={'visit-us-image-slide-' + index}>
      <StyledFluidImage fluid={slide.fluid} alt='' />
    </StyledSlideContainer>
  ))

  return (
    <StyledVisitUs>
      <StyledVisitUsContainer>
        <StyledCopyContainer>
          <StyledTitle>
            <StyledStrong>Visítanos en {' '}</StyledStrong>nuestra sucursal
          </StyledTitle>
          <StyledButton
            onClick={() => {
              navigate('/contacto')
            }}
          >
            Contáctanos
          </StyledButton>
        </StyledCopyContainer>
        <StyledSliderContainer>
          <Slider {...sliderSettings}>
            {slidesRendered}            
          </Slider>
        </StyledSliderContainer>
      </StyledVisitUsContainer>      
    </StyledVisitUs>
  )
}

export default VisitUs
