// Packages
import styled from 'styled-components'

// Components
import Button from '../Button'
import FluidImage from '../FluidImage'

export const StyledVisitUs = styled.section`
  background-color: rgb(111, 235, 200, 0.4);
  font-family: 'PT Sans Narrow', 'PT Sans', sans-serif;
  height: 100%;
  margin: 1rem 0 2rem 0rem;
  mix-blend-mode: multiply;
  position: relative;
  width: 100%;
`

export const StyledVisitUsContainer = styled.div`
  display: inline-grid;
  grid-template-columns: auto 12.75rem;
  height: 100%;
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    grid-template-columns: 30% 70%;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) { 
    grid-template-columns: 30% 70%;
  }
`

export const StyledCopyContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  width: 100%;
`

export const StyledSliderContainer = styled.div`  
  height: 100%;
  max-height: 8.5rem;
  
  .slick-slider {
    height: 100%;
  }  

  .slick-dots {
    bottom: 0.5rem;

    li {
      margin: 0;

      button {
        ::before {
          color: #B9B9B9;          
          font-size: 0.7rem;
        }      
      }
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-height: 100%;
  }
`

export const StyledButton = styled(Button)`
  width: 8rem;
`

export const StyledParagraph = styled.p`
  margin-bottom: 1rem;
  line-height: 1.3;
  text-align: center;
  width: 100%;
`

export const StyledStrong = styled.strong`
  font-weight: 700;
`

export const StyledFluidImage = styled(FluidImage)`
  margin: -0.1rem 0;
  height: 100%;
  width: 100%;
`

export const StyledSlideContainer = styled.div`
  height: 100% !important;
  margin: auto 0;
`

export const StyledTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  line-height: 1.3;
  text-align: center;
  width: 100%;
`
