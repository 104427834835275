// Packages
import styled from 'styled-components'

export const StyledHomeExperienceDisclaimer = styled.section`
  background-color: #AEEAE3;
  display: flex;
  justify-content: center;
  mix-blend-mode: multiply;
`

export const StyledHomeExperienceDisclaimerContainer = styled.div`
  padding: 1.5rem 1rem;  

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-width: 90%;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    padding: 1.5rem 0;
  }
`

export const StyledParagraph = styled.p`
  font-size: 1.15rem;
  line-height: 1.3;
  text-align: center;  
`

export const StyledStrong = styled.strong`
  font-style: italic;
  font-weight: 700;
`
