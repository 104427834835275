// Packages
import React from 'react'

// Styled components
import { StyledWeGo, StyledIconWrapper, StyledCircle, StyledIconContainer, StyledIconsContainer, StyledDescription, StyledIcon, StyledWrapper, StyledTitle, StyledButton, StyledButtonContainer, StyledIconCarContainer } from './styledComponents'
 
// Assets
import House from '../../../data/assets/img/house.svg'
import Calendar from '../../../data/assets/img/calendar.svg'
import Clock from '../../../data/assets/img/clock.svg'
import Car from '../../../data/assets/img/auto.svg'

const WeGo = () => {
  const onClickButton = () => {
    typeof window !== 'undefined' && window.open('https://api.whatsapp.com/send?phone=+528333671739&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n', '_blank')
  }

  return (
    <StyledWeGo>
      <StyledWrapper>
        <StyledTitle>
          Nosotros vamos pruebas a domicilio u oficina<StyledIconCarContainer><StyledIconWrapper src={Car} /></StyledIconCarContainer>
        </StyledTitle>
        <StyledIconsContainer>
          <StyledIconContainer>
            <StyledIcon>
              <StyledIconWrapper src={Calendar} />
              <StyledCircle>1</StyledCircle>
            </StyledIcon>
            <StyledDescription>Agenda tu cita por Whatsapp</StyledDescription>
          </StyledIconContainer>
          <StyledIconContainer>
            <StyledIcon>
              <StyledIconWrapper src={House} />
              <StyledCircle>2</StyledCircle>
            </StyledIcon>
            <StyledDescription>Realizamos la prueba en tu domicilio u oficina</StyledDescription>
          </StyledIconContainer>
          <StyledIconContainer>
            <StyledIcon>
              <StyledIconWrapper src={Clock} />
              <StyledCircle>3</StyledCircle>
            </StyledIcon>
            <StyledDescription>Resultados max. en 48 horas</StyledDescription>
          </StyledIconContainer>     
        </StyledIconsContainer>
        <StyledButtonContainer>
          <StyledButton onClick={onClickButton}>Agendar mi cita</StyledButton>
        </StyledButtonContainer>
      </StyledWrapper>
    </StyledWeGo>
  )
}

export default WeGo
