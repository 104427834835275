// Packages
import styled from 'styled-components'

// components
import Button from '../Button'

export const StyledWeKeepStandards = styled.section`
  font-family: 'PT Sans Narrow', 'PT Sans', sans-serif;
  margin: 1rem 0;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    display: flex;
    justify-content: center;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    margin: 0;
  }
`

export const StyledContainer = styled.div`  
  display: inline-grid;  
  grid-template-columns: 1fr 1fr;
  padding: 0 1rem;

  @media (min-width: ${props => props.theme.breakpoints.medium}px) {
    max-width: 80%;
    padding: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints.large}px) {
    max-width: 100%;
    padding: 0;
  }
`

export const StyledCopy = styled.div`      
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  
`

export const StyledSectionTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  text-align: left;
`

export const StyledButton = styled(Button)`
  text-shadow: 0 3px 6px #00000029;
`

export const StyledImageContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`

export const StyledImage = styled.img`
  height: 100%;  
  width: 100%;  
  max-width: 9rem;
`
