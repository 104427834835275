// Packages
import React from 'react'
import { graphql } from 'gatsby'

// Components
import AppLayout from '../layouts/AppLayout'
import SEO from '../components/presentational/SEO'
import Homepage from '../components/presentational/Homepage'

const IndexPage = ({ data }) => {
  const visitUsImages = data.visitUsImages.nodes.map(edge => {
    return {
      fluid: edge.childImageSharp.fluid
    }
  })

  const banners = [
    {
      mobileImage: data.bannerMobile1.childImageSharp.fluid,
      desktopImage: data.bannerDesktop1.childImageSharp.fluid,
      to: null,
      onClick: () => {
        typeof window !== 'undefined' && window.open('https://api.whatsapp.com/send?phone=+528333671739&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n', '_blank') // WhatsApp
      }
    },
    {
      mobileImage: data.bannerMobile2.childImageSharp.fluid,
      desktopImage: data.bannerDesktop2.childImageSharp.fluid,
      to: null,
      onClick: () => {
        typeof window !== 'undefined' && window.open('https://api.whatsapp.com/send?phone=+528333671739&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n', '_blank') // WhatsApp
      }
    },
    {
      mobileImage: data.bannerMobile3.childImageSharp.fluid,
      desktopImage: data.bannerDesktop3.childImageSharp.fluid,
      to: '/servicios',
      onClick: null
    },
    {
      mobileImage: data.bannerMobile4.childImageSharp.fluid,
      desktopImage: data.bannerDesktop4.childImageSharp.fluid,
      to: null,
      onClick: () => {
        typeof window !== 'undefined' && window.open('https://forms.gle/zY1A92mReWSh1xhD7', '_blank') // formulario de cotizacion
      }
    },
    {
      mobileImage: data.bannerMobile5.childImageSharp.fluid,
      desktopImage: data.bannerDesktop5.childImageSharp.fluid,
      to: null,
      onClick: () => {
        typeof window !== 'undefined' && window.open('https://forms.gle/zY1A92mReWSh1xhD7', '_blank') // formulario de cotizacion
      }
    },
    {
      mobileImage: data.bannerMobile6.childImageSharp.fluid,
      desktopImage: data.bannerDesktop6.childImageSharp.fluid,
      to: '/servicios',
      onClick: null
    },
    {
      mobileImage: data.bannerMobile7.childImageSharp.fluid,
      desktopImage: data.bannerDesktop7.childImageSharp.fluid,
      to: '/servicios',
      onClick: null
    }
  ]

  return (
    <AppLayout>
      <SEO title='Expertos en el medio clínico, cuidando de ti y tu familia.' />
      <Homepage banners={banners} visitUsImages={visitUsImages} />
    </AppLayout>
  )
}

export const query = graphql`
  query IndexPageQuery {

    visitUsImages: allFile(filter: { sourceInstanceName: { eq: "content" }, relativeDirectory: { eq: "visit-us-images" } }) {
      nodes {
        childImageSharp {
          fluid(maxWidth: 280, maxHeight: 180) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }

    bannerMobile1: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/mobile/banner_7.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 265) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerMobile2: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/mobile/banner_6.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 265) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerMobile3: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/mobile/banner_1.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 265) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerMobile4: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/mobile/banner_2.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 265) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerMobile5: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/mobile/banner_3.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 265) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerMobile6: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/mobile/banner_4.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 265) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerMobile7: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/mobile/banner_5.jpg"}) {
      childImageSharp {
        fluid(maxHeight: 265) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerDesktop1: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/desktop/banner_7.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerDesktop2: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/desktop/banner_6.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerDesktop3: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/desktop/banner_1.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerDesktop4: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/desktop/banner_2.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerDesktop5: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/desktop/banner_3.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerDesktop6: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/desktop/banner_5.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bannerDesktop7: file(sourceInstanceName: {eq: "content"}, relativePath: {eq: "home-banners/desktop/banner_5.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1600, maxHeight: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

  }
`

export default IndexPage
